export const generateQueryStringFromParams = (params) => {
  delete params.slug
  delete params.pageSize
  delete params.page
  delete params.includeProperties
  delete params.min_size
  delete params.max_size
  delete params.min_price
  delete params.max_price

  const queriesArray = []
  for (const property in params) {
    if (property === 'priceRange') {
      const priceRangeStr = `min_price=${params[property][0].min}&max_price=${params[property][0].max}`
      queriesArray.push(priceRangeStr)
    } else if (property === 'propertySizeRange') {
      const minSize = params[property][0].min
      const maxSize = params[property][0].max
      const propertySizeRangeStr = `min_size=${minSize}&max_size=${maxSize}`
      queriesArray.push(propertySizeRangeStr)
    } else if (property === 'propertyTypeData') {
      let propertyTypeDataStr
      if (Array.isArray(params[property])) {
        propertyTypeDataStr = params[property].join(',')
      } else {
        propertyTypeDataStr = params[property]
      }
      propertyTypeDataStr = `${property}=${propertyTypeDataStr}`
      queriesArray.push(propertyTypeDataStr)
    } else if (property === 'neighborhoodsData') {
      let neighborhoodsDataStr = params[property].join(',')
      neighborhoodsDataStr = `${property}=${neighborhoodsDataStr}`
      queriesArray.push(neighborhoodsDataStr)
    } else {
      const str = `${property}=${params[property]}`
      queriesArray.push(str)
    }
  }
  return `?${queriesArray.join('&')}`
}

const getPriceRange = (min_price, max_price) => {
  return [{ min: min_price ? min_price : 0, max: max_price ? max_price : 'Any' }]
}

const getPropertySizeRange = (min_size, max_size) => {
  return [{ min: min_size ? min_size : 0, max: max_size ? max_size : 35000 }]
}

const splitData = (data) => {
  return data.split(',')
}

const handleSlug = (query, filterQueries) => {
  if (query.slug && !query.propertyFor) {
    filterQueries.propertyFor = query.slug
    delete filterQueries.slug
  }
}

const handlePriceRange = (query, filterQueries) => {
  if (query.min_price || query.max_price) {
    filterQueries.priceRange = getPriceRange(query.min_price, query.max_price)
    delete filterQueries.min_price
    delete filterQueries.max_price
  }
}

const handleSizeRange = (query, filterQueries) => {
  if (query.min_size || query.max_size) {
    filterQueries.propertySizeRange = getPropertySizeRange(query.min_size, query.max_size)
    delete filterQueries.min_size
    delete filterQueries.max_size
  }
}

const handlePropertyTypeData = (query, filterQueries) => {
  if (query.propertyTypeData) {
    filterQueries.propertyTypeData = splitData(query.propertyTypeData)
  }
}

const handleNeighborhoodsData = (query, filterQueries) => {
  if (query.neighborhoodsData) {
    filterQueries.neighborhoodsData = splitData(query.neighborhoodsData)
  }
}

const handleDistrictId = (query, filterQueries) => {
  if (query.districtId && query.districtId === 'null') {
    filterQueries.districtId = null
  }
}

export const getParamsFromQuery = (query) => {
  const filterQueries = { ...query }

  handleSlug(query, filterQueries)
  handlePriceRange(query, filterQueries)
  handleSizeRange(query, filterQueries)
  handlePropertyTypeData(query, filterQueries)
  handleNeighborhoodsData(query, filterQueries)
  handleDistrictId(query, filterQueries)

  filterQueries.page = filterQueries.page || 0
  filterQueries.pageSize = filterQueries.pageSize || 16
  filterQueries.includeProperties = filterQueries.includeProperties || true

  return filterQueries
}

export const getParamsFromRefUrl = (refUrl) => {
  const referenceUrlParams = refUrl.split('?')

  const propertyForSlug = referenceUrlParams[0].split('/')[0]

  const propertyFor = propertyForSlug

  const referenceQueryString = referenceUrlParams[1]

  const refUrlParams = JSON.parse(
    '{"' + decodeURI(referenceQueryString.replace(/&/g, '","').replace(/=/g, '":"')) + '"}',
  )

  return {
    ...refUrlParams,
    propertyFor,
    page: 0,
    pageSize: 16,
    includeProperties: true,
  }
}
