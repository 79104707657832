import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import Category from './Category/Category'
import styles from './Footer.module.scss'
import SocialLinks from './SocialLinks'
import SubFooter from './SubFooter/SubFooter'
import { pushDataLayerEventHandler } from '@/utils/utils'

const Footer = (props) => {
  const { footerLinks, locale, metaData, propertyDetails, page, wrapperClass } = props
  const categoriesView =
    footerLinks &&
    Object.keys(footerLinks).map((category, index) => (
      <Category key={index} title={category} category={footerLinks[category]} />
    ))
  const { t, lang } = useTranslation('translations-auction')
  let imgSrc = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/manuals/logos/wasalt-logo${locale === 'ar' ? '-ar' : ''}.svg`

  const openPDFInNewTab = () => {
    const pdfUrl = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/license/license-auction.pdf`
    window.open(pdfUrl, '_blank')
  }

  const handleEvent = () => {
    const data = {
      REGA_logo_type: 'Auction',
    }
    pushDataLayerEventHandler(window, lang, 'REGA_logo_clicked', page, data)
  }
  
  return (
    <footer
      className={`${styles.footer} ${styles[wrapperClass]} ${page == 'auction-detail-revamp' ? styles.p_175 : ''}`}
    >
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.logo}>
            <Image width={160} height={58} src={imgSrc} alt={locale === 'ar' ? 'شعار وصلت' : 'Wasalt Logo'} />
            <div
              className={styles.regaBlock}
              onClick={() => {
                openPDFInNewTab()
                handleEvent()
              }}
            >
              <Image
                loading='lazy'
                src={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/Auction.png`}
                alt={t('common.REGA')}
                width={194}
                height={85}
              />
            </div>
          </div>
          {categoriesView}
          <div className={styles.staticContent}></div>
          <div className={styles.links}>
            <div className={styles.storeBtns}>
              {/* <a aria-label='' href=''> */}
              <Image
                onClick={() => (window.location.href = 'https://apps.apple.com/in/app/wasalt/id1588885673')}
                alt=''
                src='/images/AppStore-gray.svg'
                width={162}
                height={48}
              />
              {/* </a> */}
              {/* <a aria-label='' href=''> */}
              <Image
                onClick={() => (window.location.href = 'https://play.google.com/store/apps/details?id=com.wasalt.app')}
                alt=''
                src='/images/GooglePlay-gray .svg'
                width={162}
                height={48}
              />
              {/* </a> */}
            </div>
            <div className={styles.socialIcons}>
              <SocialLinks lang={lang} page={page}/>
            </div>
          </div>
        </div>
      </div>
      <SubFooter metaData={metaData} locale={locale} propertyDetails={propertyDetails} page={page} />
    </footer>
  )
}
export default Footer
